<template>
    <workspace-wrapper>
        <template #workspace>
            <mines-workspace :layout="stateData.currentSpinResult.layout"
                             :selects="stateData.currentSpinResult.selects"
            />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import MinesWorkspace from "./MinesWorkspace";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        layout: [],
                        selects: [],
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        MinesWorkspace
    }
}
</script>

<style scoped>

</style>
