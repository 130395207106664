<template>
    <div>
        <table>
            <tr v-for="(row, rowIndex) in reversedLayout"
                :key="rowIndex"
            >
                <td v-for="(symbol, reelIndex) in row"
                    :key="reelIndex"
                >
                    <template v-if="symbol === 'S'">

                        <div v-if="isSelected(rowIndex, reelIndex)" :style='{ backgroundImage: `url( ${selectedDiamondSymbolPath} )` }'
                             class="layout__symbol"
                        />

                        <div v-else :style='{ backgroundImage: `url( ${diamondSymbolPath} )` }'
                             class="layout__symbol"
                        />
                    </template>

                    <div v-else-if="symbol === 'M'" :style='{ backgroundImage: `url( ${boomSymbolPath} )` }'
                         class="layout__symbol"
                    />

                    <div v-else class="layout__symbol" />
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        layout: {
            type: Array,
            required: true,
        },
        selects: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            chunkSize: 5,
            symbol: {
                size: 80
            }
        }
    },
    computed: {
        reversedLayout() {
            let result = [];
            for (let i = 0; i < this.layout.length; i += this.chunkSize) {
                result.push(this.layout.slice(i, i + this.chunkSize));
            }
            return result;
        },
        boomSymbolPath() {
            return require(`@/assets/games/mini/mines/boom.png`);
        },
        selectedDiamondSymbolPath() {
            return require(`@/assets/games/mini/mines/selected-diamond.png`);
        },
        diamondSymbolPath() {
            return require(`@/assets/games/mini/mines/diamond.png`);
        }
    },
    methods: {
        isSelected(rowIndex, reelIndex) {
            if (this.selects === null) {
                return false;
            }

            return this.selects.includes(rowIndex * this.chunkSize + reelIndex);
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', this.symbol.size * this.chunkSize);
        this.$store.dispatch('workspace/setWidth', this.symbol.size * this.chunkSize);
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    margin: auto;
}

td {
    border: 1px solid #dee2e6 !important;
}

.layout__symbol {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80px;
    height: 80px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}
</style>
